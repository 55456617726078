<fstr-link abindex="-1" [button]="{ text: '', url: tip.url.join(), routerLink: tip.url }" [cssClasses]="'task-card block-link'">
  <article class="update-card card">
    <figure class="update-card__crop mb-md-2">
      <fstr-image [image]="image" [imageSizes]="[800]" [width]="600" [height]="300" [alt]="" [decorative]="true"></fstr-image>
    </figure>

    <div class="update-card__content">
      <h1 class="h6 mb-0 mb-md-2 update-card__title text-secondary">
        <a tabindex="-1" [routerLink]="tip.url" class="block-link">{{ tip.title }}</a>
      </h1>

      <p class="update-card__content__text type-body-xxs d-none d-md-block mb-3" *ngIf="tip.intro">
        {{ tip.intro | truncate: 200 }}
      </p>

      <footer class="mt-md-auto mb-0">
        <a class="button button-link button--primary" *ngIf="tip.url" [routerLink]="tip.url">
          <span>{{ 'lees-meer-tekst' | fromDictionary }}</span>
          <fstr-icon [anchor]="'icon-arrow-right'"></fstr-icon>
        </a>
      </footer>
    </div>
  </article>
</fstr-link>
